@if (transactions.length) {
  <div class="box">
    <div class="title">
      <h2>
        @if(modalTitle) {
          {{modalTitle}}
        } @else {
          Transactions
        }

        @if (modal && transactions.length > 1) { ({{transactions.length | number}}) }
      </h2>
      @if (modal) {
        <div class="options">
          <div (click)="closeModal()" matTooltip="Close"><mat-icon class="material-icons-outlined">close</mat-icon></div>
        </div>
      }
    </div>
    <div class="table-groups transactions-table" [ngClass]="{'table-in-modal': modal}" [ngStyle]="{'max-height': (modal && height ? height + 'px' : '')}">
      <div class="table-group-row table-header">
        <div class="table-group-cell">Category</div>
        <div class="table-group-cell">Amount</div>
        <div class="table-group-cell comment">Comment</div>
        <div class="table-group-cell date">Date</div>
        <div class="table-group-cell actions"></div>
      </div>
      @for(item of transactions; track transactions.created_at) {
        @if ($index > 0 && addSeparator(item, transactions[$index - 1])) {
          <div class="table-group-row separator">
            <span>{{item.created_at * 1000 | date: 'MMMM y'}}</span>
          </div>
        }

        <div class="table-group-row">
          <div class="table-group-cell" [matTooltip]="getName(item)">{{ item.parent_category_name }} - {{ item.category_name }}</div>
          <div class="table-group-cell amount {{item.type}}">{{ item.amount | currency: user.currency : 'symbol-narrow' | fixCurrency}}</div>
          <div class="table-group-cell comment" [matTooltip]="getComment(item)">{{ item.comment }}</div>
          <div class="table-group-cell date">{{ item.created_at * 1000 | date: 'd MMM, y HH:mm' }}</div>
          <div class="table-group-cell actions">
              @if (modal) {
                <div class="btn edit" (click)="closeModal()" routerLink="/transactions/{{item.id}}" matTooltip="Edit"></div>
              } @else {
                <div class="btn edit" routerLink="/transactions/{{item.id}}" matTooltip="Edit"></div>
              }
          </div>
        </div>
      }
    </div>
  </div>
} @else {
  @if (loading) {
    <div class="loader">
      <mat-progress-spinner strokeWidth="2" mode="indeterminate"></mat-progress-spinner>
    </div>
  } @else {
    <app-no-records-screen
      [title]="'You still don\'t have any transactions'"
      [description]="'To get your first transaction, first you must create a field and then add a transaction to it.'"
      [icon]="'account_balance'"
    ></app-no-records-screen>
  }
}

