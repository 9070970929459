import {Component, Input} from '@angular/core';
import {MatIcon} from "@angular/material/icon";

@Component({
  selector: 'app-no-records-screen',
  standalone: true,
  imports: [
    MatIcon
  ],
  templateUrl: './no-records-screen.component.html',
  styleUrl: './no-records-screen.component.css'
})
export class NoRecordsScreenComponent {
  @Input() title!: string;
  @Input() description!: string;
  @Input() icon!: string;
}
