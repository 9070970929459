import {Component, Input} from '@angular/core';
import {DatePipe, CurrencyPipe, NgClass, NgStyle, DecimalPipe} from "@angular/common";
import {MatIcon} from "@angular/material/icon";
import {AuthService} from "../../services/auth.service";
import {NoRecordsScreenComponent} from "../no-records-screen/no-records-screen.component";
import {RouterLink, RouterLinkActive} from "@angular/router";
import {FixCurrencyPipe} from "../../pipes/fix-currency.pipe";
import {MatTooltip} from "@angular/material/tooltip";
import {MatProgressSpinner} from "@angular/material/progress-spinner";

@Component({
  selector: 'app-list-transactions',
  standalone: true,
  imports: [
    DatePipe,
    CurrencyPipe,
    MatIcon,
    NoRecordsScreenComponent,
    NgClass,
    NgStyle,
    RouterLink,
    RouterLinkActive,
    FixCurrencyPipe,
    DecimalPipe,
    MatTooltip,
    MatProgressSpinner
  ],
  templateUrl: './list-transactions.component.html',
  styleUrl: './list-transactions.component.css'
})
export class ListTransactionsComponent {
  @Input() transactions: any = [];
  @Input() loading?: boolean;
  modal?: boolean = false;
  modalRef?: any;
  modalTitle?: string;
  height?: number = 500;
  user: any;
  date: Date = new Date();

  constructor(private authService: AuthService) {
    this.user = this.authService.user;
  }

  addSeparator(currentItem: any, previousItem: any): boolean {
    let result = false;

    const currentItemDate = new Date(currentItem.created_at * 1000);
    const previousItemDate = new Date(previousItem.created_at * 1000);

    if (currentItemDate.getMonth() !== previousItemDate.getMonth()) {
      result = true;
    }

    return result;
  }

  getName(item: any): string {
    return item.parent_category_name + ' - ' + item.category_name;
  }

  getComment(item: any): string {
    return item.comment;
  }

  closeModal(): void {
    this.modalRef.close();
  }

  protected readonly DatePipe = DatePipe;
}
