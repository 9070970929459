import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Transactions} from "../models/Transactions";
import {AuthService} from "./auth.service";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class TransactionsService {

  constructor(private http: HttpClient, private authService: AuthService) { }

  fetch(page: number): Observable<any> {
    return this.http.get<Observable<any>>(environment.backendUrl + '/transactions?page=' + page, {withCredentials: true});
  }

  get(id: string): Observable<Transactions> {
    return this.http.get<Transactions>(environment.backendUrl + '/transactions/' + id, {withCredentials: true});
  }

  add(transaction: Transactions): Observable<Transactions> {
    return this.http.post<Transactions>(environment.backendUrl + '/transactions', transaction, {withCredentials: true});
  }

  update(transaction: Transactions): Observable<any> {
    return this.http.post<Observable<any>>(environment.backendUrl + '/transactions/' + transaction.id, transaction, {withCredentials: true});
  }

  delete(transaction: Transactions): Observable<any> {
    return this.http.delete<Observable<any>>(environment.backendUrl + '/transactions/' + transaction.id, {withCredentials: true});
  }

  list(year: number, month: number, fieldIds: any, day?: number, type?: string): Observable<any> {
    return this.http.post<Observable<any>>(environment.backendUrl + '/transactions/' + year + '/' + (month + 1) + (day ? '/' + day : ''), {field_ids: fieldIds, type: type}, {withCredentials: true});
  }

  monthlySpentData(year: number, month: number): Observable<any> {
    return this.http.get<Observable<any>>(environment.backendUrl + '/transactions/accumulated/spent/' + year + '/' + (month + 1), {withCredentials: true});
  }

  monthlyIncomeData(year: number, month: number): Observable<any> {
    return this.http.get<Observable<any>>(environment.backendUrl + '/transactions/accumulated/income/' + year + '/' + (month + 1), {withCredentials: true});
  }

  getStatistics(year: number, month: number): Observable<any> {
    return this.http.get<Observable<any>>(environment.backendUrl + '/users/' + this.authService.user.id + '/transactions/statistics/' + year + '/' + (month + 1), {withCredentials: true});
  }

  calendar(year: number, month: number): Observable<any> {
    return this.http.get<Observable<any>>(environment.backendUrl + '/users/'+ this.authService.user.id + '/calendar/' + year + '/' + (month + 1), {withCredentials: true});
  }
}
